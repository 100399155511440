import React from 'react';

export const IconTwitter = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" fill="none">
      <path
        fill="#000"
        d="M20 3.8c-.7.3-1.5.5-2.4.6a4 4 0 001.8-2.2c-.8.5-1.6.8-2.6 1a4.1 4.1 0 00-7 3.7c-3.4-.2-6.4-1.8-8.4-4.3a4.1 4.1 0 001.3 5.5 4 4 0 01-1.9-.5c0 2 1.4 3.7 3.3 4a4 4 0 01-1.9.1c.6 1.7 2 2.9 3.9 2.9A8.2 8.2 0 010 16.3C1.8 17.5 4 18 6.3 18A11.6 11.6 0 0017.9 6c.9-.6 1.5-1.3 2.1-2.1z"
      />
    </svg>
  );
};
